import React, { FC, useMemo } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Divider, RadioButton, RadioButtonGroup } from 'wix-ui-tpa';

import { IWidgetProps } from '../Widget';
import {
  CommonForumFacetOptions,
  ContentTypeOptions,
  ContentTypeOptionsType,
} from './ForumFacets.types';
import {
  getActiveType,
  getTypeCounts,
  getListTotals,
  getActiveCategory,
} from './ForumFacets.utils';
import { st, classes } from './ForumFacets.st.css';

interface ForumFacetsProps {
  facets: IWidgetProps['forumFacets'];
  onFacetsFilterChange: IWidgetProps['onForumFacetsFilterChange'];
  onFacetsFilterReset: IWidgetProps['onForumFacetsFilterReset'];
}

const ForumFacets: FC<ForumFacetsProps> = ({
  facets,
  onFacetsFilterChange,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { filter, categories, contentTypes } = facets;

  const activeType = useMemo(() => getActiveType(filter), [filter]);
  const activeCategory = useMemo(() => getActiveCategory(filter), [filter]);
  const currentTypeCounts = useMemo(
    () => getTypeCounts(contentTypes),
    [contentTypes],
  );

  const hasCategories = categories?.length > 0;

  return (
    <div className={st(classes.root, { mobileView: isMobile })}>
      <div className={classes.subtitle}>
        {t('searchResults.tabs.label.forums.filter.type')}
      </div>

      <RadioButtonGroup
        withSpacing
        name="contentType"
        value={activeType}
        onChange={(value: ContentTypeOptionsType) => {
          if (value === CommonForumFacetOptions.All) {
            onFacetsFilterChange({
              contentType: undefined,
              categoryTitle: undefined,
            });
          } else {
            onFacetsFilterChange({
              contentType: value,
              categoryTitle: undefined, // Should reset current category, because it's unknown if category includes new type documents
            });
          }
        }}
      >
        <RadioButton value={ContentTypeOptions.All}>
          {t('searchResults.tabs.label.forums.filter.allTypes')}
          <span className={classes.itemCount}>({currentTypeCounts.ALL})</span>
        </RadioButton>
        <RadioButton value={ContentTypeOptions.Post}>
          {t('searchResults.tabs.label.forums.filter.posts')}
          <span className={classes.itemCount}>({currentTypeCounts.POST})</span>
        </RadioButton>
        <RadioButton value={ContentTypeOptions.Comment}>
          {t('searchResults.tabs.label.forums.filter.comments')}
          <span className={classes.itemCount}>
            ({currentTypeCounts.COMMENT})
          </span>
        </RadioButton>
      </RadioButtonGroup>

      {hasCategories && (
        <>
          <Divider className={classes.divider} />

          <div className={classes.subtitle}>
            {t('searchResults.tabs.label.forums.filter.category')}
          </div>

          <RadioButtonGroup
            withSpacing
            name="categoryTitle"
            value={activeCategory}
            onChange={(value: string) => {
              const categoryTitle =
                value !== CommonForumFacetOptions.All ? value : undefined;

              onFacetsFilterChange({
                categoryTitle,
              });
            }}
          >
            <RadioButton value={CommonForumFacetOptions.All}>
              {t('searchResults.tabs.label.forums.filter.allCategories')}
              <span className={classes.itemCount}>
                ({getListTotals(categories)})
              </span>
            </RadioButton>

            {categories.map(({ value, count }) => (
              <RadioButton key={value} value={value}>
                {value}
                <span className={classes.itemCount}>({count})</span>
              </RadioButton>
            ))}
          </RadioButtonGroup>
        </>
      )}
    </div>
  );
};

export default ForumFacets;
